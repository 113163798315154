import { default as _91_46_46_46all_93TmU3DUCuajMeta } from "/Users/darsh/Development/workspace-public-website/pages/[...all].vue?macro=true";
import { default as _91id_937MMIcBr8zLMeta } from "/Users/darsh/Development/workspace-public-website/pages/estimates/[id].vue?macro=true";
import { default as indexjTJARFRZhkMeta } from "/Users/darsh/Development/workspace-public-website/pages/estimates/index.vue?macro=true";
import { default as _91id_93kHBM6dyhC9Meta } from "/Users/darsh/Development/workspace-public-website/pages/invoices/[id].vue?macro=true";
import { default as indexqaMfDyWbnUMeta } from "/Users/darsh/Development/workspace-public-website/pages/invoices/index.vue?macro=true";
import { default as transfer_45failf9CjeXzW2lMeta } from "/Users/darsh/Development/workspace-public-website/pages/transfer-fail.vue?macro=true";
import { default as transfer_45success6ofeKq367JMeta } from "/Users/darsh/Development/workspace-public-website/pages/transfer-success.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93TmU3DUCuajMeta?.name ?? "all",
    path: _91_46_46_46all_93TmU3DUCuajMeta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_93TmU3DUCuajMeta || {},
    alias: _91_46_46_46all_93TmU3DUCuajMeta?.alias || [],
    redirect: _91_46_46_46all_93TmU3DUCuajMeta?.redirect || undefined,
    component: () => import("/Users/darsh/Development/workspace-public-website/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91id_937MMIcBr8zLMeta?.name ?? "estimates-id",
    path: _91id_937MMIcBr8zLMeta?.path ?? "/estimates/:id()",
    meta: _91id_937MMIcBr8zLMeta || {},
    alias: _91id_937MMIcBr8zLMeta?.alias || [],
    redirect: _91id_937MMIcBr8zLMeta?.redirect || undefined,
    component: () => import("/Users/darsh/Development/workspace-public-website/pages/estimates/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjTJARFRZhkMeta?.name ?? "estimates",
    path: indexjTJARFRZhkMeta?.path ?? "/estimates",
    meta: indexjTJARFRZhkMeta || {},
    alias: indexjTJARFRZhkMeta?.alias || [],
    redirect: indexjTJARFRZhkMeta?.redirect || undefined,
    component: () => import("/Users/darsh/Development/workspace-public-website/pages/estimates/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93kHBM6dyhC9Meta?.name ?? "invoices-id",
    path: _91id_93kHBM6dyhC9Meta?.path ?? "/invoices/:id()",
    meta: _91id_93kHBM6dyhC9Meta || {},
    alias: _91id_93kHBM6dyhC9Meta?.alias || [],
    redirect: _91id_93kHBM6dyhC9Meta?.redirect || undefined,
    component: () => import("/Users/darsh/Development/workspace-public-website/pages/invoices/[id].vue").then(m => m.default || m)
  },
  {
    name: indexqaMfDyWbnUMeta?.name ?? "invoices",
    path: indexqaMfDyWbnUMeta?.path ?? "/invoices",
    meta: indexqaMfDyWbnUMeta || {},
    alias: indexqaMfDyWbnUMeta?.alias || [],
    redirect: indexqaMfDyWbnUMeta?.redirect || undefined,
    component: () => import("/Users/darsh/Development/workspace-public-website/pages/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: transfer_45failf9CjeXzW2lMeta?.name ?? "transfer-fail",
    path: transfer_45failf9CjeXzW2lMeta?.path ?? "/transfer-fail",
    meta: transfer_45failf9CjeXzW2lMeta || {},
    alias: transfer_45failf9CjeXzW2lMeta?.alias || [],
    redirect: transfer_45failf9CjeXzW2lMeta?.redirect || undefined,
    component: () => import("/Users/darsh/Development/workspace-public-website/pages/transfer-fail.vue").then(m => m.default || m)
  },
  {
    name: transfer_45success6ofeKq367JMeta?.name ?? "transfer-success",
    path: transfer_45success6ofeKq367JMeta?.path ?? "/transfer-success",
    meta: transfer_45success6ofeKq367JMeta || {},
    alias: transfer_45success6ofeKq367JMeta?.alias || [],
    redirect: transfer_45success6ofeKq367JMeta?.redirect || undefined,
    component: () => import("/Users/darsh/Development/workspace-public-website/pages/transfer-success.vue").then(m => m.default || m)
  }
]