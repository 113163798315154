import revive_payload_client_dNjkIl3ku6 from "/Users/darsh/Development/workspace-public-website/node_modules/.pnpm/nuxt@3.9.1_eslint@8.56.0_sass@1.70.0_typescript@5.3.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_d0DB9llBQe from "/Users/darsh/Development/workspace-public-website/node_modules/.pnpm/nuxt@3.9.1_eslint@8.56.0_sass@1.70.0_typescript@5.3.3_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_00lm5zibpJ from "/Users/darsh/Development/workspace-public-website/node_modules/.pnpm/nuxt@3.9.1_eslint@8.56.0_sass@1.70.0_typescript@5.3.3_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_Vd1TCu88sX from "/Users/darsh/Development/workspace-public-website/node_modules/.pnpm/nuxt@3.9.1_eslint@8.56.0_sass@1.70.0_typescript@5.3.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_UOWyczpX10 from "/Users/darsh/Development/workspace-public-website/node_modules/.pnpm/nuxt@3.9.1_eslint@8.56.0_sass@1.70.0_typescript@5.3.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_EBwQt5XFH2 from "/Users/darsh/Development/workspace-public-website/node_modules/.pnpm/nuxt@3.9.1_eslint@8.56.0_sass@1.70.0_typescript@5.3.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_9qlVugdijF from "/Users/darsh/Development/workspace-public-website/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.3.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/darsh/Development/workspace-public-website/.nuxt/components.plugin.mjs";
import prefetch_client_pvAqfhRvVr from "/Users/darsh/Development/workspace-public-website/node_modules/.pnpm/nuxt@3.9.1_eslint@8.56.0_sass@1.70.0_typescript@5.3.3_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/Users/darsh/Development/workspace-public-website/.nuxt/formkitPlugin.mjs";
import element_plus_teleports_plugin_h4Dmekbj62 from "/Users/darsh/Development/workspace-public-website/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/Users/darsh/Development/workspace-public-website/.nuxt/element-plus-injection.plugin.mjs";
import plugin_client_3dFO4wL2SD from "/Users/darsh/Development/workspace-public-website/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import unocss_MzCDxu9LMj from "/Users/darsh/Development/workspace-public-website/.nuxt/unocss.mjs";
import primevue_plugin_egKpok8Auk from "/Users/darsh/Development/workspace-public-website/.nuxt/primevue-plugin.mjs";
import plugin_client_b6UqZ5tVZj from "/Users/darsh/Development/workspace-public-website/node_modules/.pnpm/nuxt-primevue@0.2.2/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import chunk_reload_client_6DP0hgkLlb from "/Users/darsh/Development/workspace-public-website/node_modules/.pnpm/nuxt@3.9.1_eslint@8.56.0_sass@1.70.0_typescript@5.3.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import ssr_plugin_DbKMEX7hCt from "/Users/darsh/Development/workspace-public-website/node_modules/.pnpm/@vueuse+nuxt@10.7.2_nuxt@3.9.1/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
export default [
  revive_payload_client_dNjkIl3ku6,
  unhead_d0DB9llBQe,
  router_00lm5zibpJ,
  debug_Vd1TCu88sX,
  payload_client_UOWyczpX10,
  check_outdated_build_client_EBwQt5XFH2,
  plugin_vue3_9qlVugdijF,
  components_plugin_KR1HBZs4kY,
  prefetch_client_pvAqfhRvVr,
  formkitPlugin_pZqjah0RUG,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  plugin_client_3dFO4wL2SD,
  unocss_MzCDxu9LMj,
  primevue_plugin_egKpok8Auk,
  plugin_client_b6UqZ5tVZj,
  chunk_reload_client_6DP0hgkLlb,
  ssr_plugin_DbKMEX7hCt
]