<script setup lang="ts">
import 'primevue/resources/themes/lara-light-green/theme.css'
useHead({
  meta: [
    { name: "description", content: "Business" },
    { property: "og:title", content: "Workspace369" },
    {
      property: "og:image",
      content: "",
    },
  ],
});
</script>

<template>
  <NuxtLayout>
    <SeoKit />
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="postcss">
html,
body,
#__nuxt {
  height: 100vh;
  margin: 0;
  padding: 0;
  background: transparent;
}

html.dark {
  background: #222;
  color: white;
}
</style>
